import { template as template_86da8e4b4b484e2ba7ffc68ee6595248 } from "@ember/template-compiler";
const FKText = template_86da8e4b4b484e2ba7ffc68ee6595248(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
