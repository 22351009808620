import { template as template_83d57c143a9b48d6bec5d40849eeb510 } from "@ember/template-compiler";
const FKLabel = template_83d57c143a9b48d6bec5d40849eeb510(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
