import { template as template_5b5e580ce6884351a3109f3666b0eaf8 } from "@ember/template-compiler";
const WelcomeHeader = template_5b5e580ce6884351a3109f3666b0eaf8(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
